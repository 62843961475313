.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.info {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .about-content {
    flex-direction: row;
    align-items: flex-start;
  }

  .avatar {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .info {
    text-align: left;
  }
}